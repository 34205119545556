export default function (button) {
	if (!button || !button.buttonText) return null;

	button = JSON.parse(JSON.stringify(button));

	switch (button.buttonSize) {
		case "Dynamic":
			button.size = "m";
			button.dynamic = true;
			break;
		case "Dynamic with arrow":
			button.size = "m";
			button.dynamic = true;
			button.chevron = true;
			break;
		case "Medium":
			button.size = "m";
			button.dynamic = false;
			break;
		case "Large":
			button.size = "l";
			button.dynamic = false;
			break;
		case "Large with arrow":
			button.size = "l";
			button.dynamic = false;
			button.chevron = true;
			break;
		default:
			button.size = "m";
	}

	if (button.buttonSecondaryText) {
		button.secondaryText = button.buttonSecondaryText;
	}
	return {
		href: button.href || (button.buttonUrl ? button.buttonUrl.externalUrl : "javascript:"),
		to: (button.buttonUrl && button.buttonUrl.internalUrl) || "",
		text: button.secondaryText ? button.buttonText + " " + button.secondaryText : button.buttonText || "",
		chevron: button.chevron,
		size: button.size,
		dynamic: button.dynamic,
		secondaryText: button.secondaryText,
		// secondary: button.buttonColorType,
		disabled: button.disabled,
		iconRight: button.chevron ? "chevron-right" : null,
	};
}
